import React, { useEffect, useState } from 'react';

import { Button, Collapse, CollapseProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { ArrowDownIcon, LeftArrowIcon } from '../../components/Icons';
import Main from '../../components/layout/Main';
import MatchTimer from '../../components/MatchTimer';
import ExitMatchModal from '../../components/Modals/ExitMatchModal';
import ScoreReviewModal from '../../components/Modals/ScoreReviewModal';
import RecordingButton from '../../components/RecordingButton';
import { clearEvents } from '../../core/event/eventApi';
import { getMatch, updateMatch, clearMatch } from '../../core/match/matchApi';
import { AppDispatch, CombinedStates } from '../../core/types';

import EventsListing from './EventsListing';

import './matchLog.scss';

const MatchLog = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { matchData, isLoading: matchIsLoading, error: matchError } = useSelector((state: CombinedStates) => state.match);

    const [isTimerActive, setIsTimerActive] = useState<boolean>(false);

    const [isExitModalOpen, setExitModalOpen] = useState(false);
    const [isScoreModalOpen, setScoreModalOpen] = useState(false);
    const [isLoadingScore, setLoadingScore] = useState(true);
    const [isConfirmLoading, setConfirmLoading] = useState(false);

    useEffect(() => {
        if (!matchData) {
            navigate('/dashboard');
        }

    }, [matchData, navigate]);

    const showExitModal = () => {
        setIsTimerActive(false);
        setExitModalOpen(true);
    };

    const closeExitModal = () => {
        setIsTimerActive(true);
        setExitModalOpen(false);
        setConfirmLoading(false); // Ensure confirm loading is reset
    };
    const showScoreModal = async () => {
        setScoreModalOpen(true); // Open the modal immediately
        if (!matchData) {
            return;
        }

        setLoadingScore(true); // Start loading state
        await dispatch(getMatch({ match_id: matchData.id }));
        setLoadingScore(false); // End loading state once the data is fetched
    };

    const closeScoreModal = () => {
        setScoreModalOpen(false);
        setLoadingScore(false); // Reset loading score state on close
    };

    const handleExitMatch = () => {
        setConfirmLoading(true);
        setExitModalOpen(false);
        setConfirmLoading(false);

        dispatch(clearMatch());
        dispatch(clearEvents());

        navigate('/dashboard');
    };

    const handleSubmitData = async () => {
        if (!matchData) return;

        setConfirmLoading(true);
        await dispatch(updateMatch({
            match_id: matchData.id,
        }));
        setConfirmLoading(false);
        closeScoreModal();
        navigate('/confirmation');
    };

    useEffect(() => {
        if (isScoreModalOpen && !matchError) {
            setConfirmLoading(false); // Ensure confirm loading is reset
        }
    }, [matchIsLoading, isScoreModalOpen, isLoadingScore, matchError]);

    const handleScoreUpdate = () => {
        closeScoreModal();
        navigate('/review-score');
    };

    const backLink = (
        <Button type="text" onClick={() => showExitModal()}>
            <LeftArrowIcon />
        </Button>
    );

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Prompt Terminology',
            children: (
                <div className="accordion-content-wrap">
                    <p>
                        KickStat&apos;s AI tool understands specific performance prompts.
                        Please use the following key terms in your commentary to ensure KickStats can provide the most accurate results:
                    </p>
                    <p><strong className="primary-color">Shooting:</strong></p>
                    <p>Goal (Keywords: Goal, Score) - Use when a player scores a goal</p>
                    <p>Shot (Keywords: Shot, Shoot, Strike) - Use when a player shoots on or off target</p>
                    <p><strong className="primary-color">Passing:</strong></p>
                    <p>Pass (Keywords: Pass, Cross) - Use when a player attempts to pass the ball to a teammate</p>
                    <p>Assist (Keywords: Assist) - Use when a player passes to a team mate who scores a goal</p>
                    <p><strong className="primary-color">Dribbling:</strong></p>
                    <p>Dribble (Keywords: Dribble, Run/Carries Ball) - Use when a player moves with the ball under control at their feet</p>
                    <p><strong className="primary-color">Defending:</strong></p>
                    <p>Tackle (Keywords: Tackle, Dispossess, Challenge) - Use when a player tackles an opponent and wins back the ball. </p>
                    <p><strong className="primary-color">Goal Keeping:</strong></p>
                    <p>Save (Keywords: Save, Parry, Catch, Smother) - Use when a keeper makes a save from a shot this is either on or off target. </p>
                    <p>For the best results please only commentate on your own team except for when your opponent scores a goal.
                        By logging your opponent&apos;s goals by saying &ldquo;Goal [Opponent name]&ldquo; the KickStats AI will be able to calculate the final score.
                    </p>
                </div>
            ),
        },
    ];

    const renderExpandIcon = (isActive: boolean) => (<ArrowDownIcon rotate={isActive ? -180 : 0} />);

    if (!matchData) {
        return null;
    }

    return (
        <Main
            showHeader
            className="match-log"
            title={<div>Match Log</div>}
            headerTitle={<div>Match Log</div>}
            floatingHeader={false}
            backLink={backLink}
            showFooter={false}
            floatingFooter={false}>
            <div className="content-wrap">
                <MatchTimer
                    isActive={isTimerActive}
                    setIsActive={setIsTimerActive}
                    onStop={showScoreModal} />

                <div className="accordion-wrap shadow-ctn">
                    <Collapse
                        items={items}
                        bordered={false}
                        expandIcon={({ isActive }) => renderExpandIcon(isActive ?? false)}
                        expandIconPosition="end" />
                </div>
                <div className="recording-wrap">
                    <RecordingButton matchId={+matchData.id} />
                </div>

                <div className="event-log-wrapper border-top">
                    <h2 className="h-1 title-style primary-color">Game Events</h2>
                    {matchData?.id && <EventsListing matchId={matchData?.id} />}
                </div>

            </div>
            <div />

            <ExitMatchModal
                open={isExitModalOpen}
                confirmLoading={isConfirmLoading}
                onCancel={closeExitModal}
                onExit={handleExitMatch} />

            <ScoreReviewModal
                open={isScoreModalOpen}
                confirmLoading={isConfirmLoading}
                loadingScore={matchIsLoading}
                onCancel={closeScoreModal}
                onSubmit={handleSubmitData}
                onUpdate={handleScoreUpdate}
                ownTeamScore={matchData?.own_team_score || 0}
                opponentScore={matchData?.opponent_score || 0} />
        </Main>
    );
};

export default MatchLog;

MatchLog.propTypes = {};
