import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { CombinedStates } from '../types';
import { fetchApiAuth } from '../utils/api';

import { Match, MatchCreateRequest, MatchUpdateRequest } from './matchTypes';

export const getMatch = createAsyncThunk<Match, { match_id: string }, {rejectValue:string}>(
    'match/get',
    async (payload, { rejectWithValue }) => {
        try {
            const { match_id } = payload;

            const response = await fetchApiAuth<Match>({
                method: 'GET',
                url: `match/${match_id}`,
            });

            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errorMessage = (error.response?.data.message as string) || 'An unknown error occurred';
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const setupMatch = createAsyncThunk<Match, MatchCreateRequest, {rejectValue:string}>(
    'match/setup',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await fetchApiAuth<Match>({
                method: 'POST',
                url: 'match',
                data: payload,
            });

            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errorMessage = (error.response?.data.message as string) || 'An unknown error occurred';
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const updateMatch = createAsyncThunk<null, MatchUpdateRequest, {state: CombinedStates, rejectValue:string}>(
    'match/update',
    async (payload, { getState, rejectWithValue }) => {
        const { match_id, ...rest } = payload;
        const url = `match/${match_id}/finish`;

        // Directly access timer.currentTime from Redux state
        const { currentTime } = getState().timer;

        try {
            await fetchApiAuth<MatchUpdateRequest>({
                method: 'PUT',
                url,
                data: {
                    ...rest,
                    actual_duration: currentTime,
                },
            });

            return null;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errorMessage = (error.response?.data.message as string) || 'An unknown error occurred';
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const clearMatch = createAsyncThunk<null, void, {rejectValue:string}>(
    'match/clearLocal',
    async () => {
        return null;
    },
);
