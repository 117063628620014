import React from 'react';

import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import logoSrc from '../../assets/images/logo-circular.svg';
import { EmailIcon, RightArrowIcon } from '../../components/Icons';
import Main from '../../components/layout/Main';
import { clearMatch } from '../../core/match/matchApi';
import { clearCurrentTime } from '../../core/timer/timerSlice';
import { AppDispatch } from '../../core/types';

import './dashboard.scss';

const Dashboard = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const handleBeginGame = () => {
        dispatch(clearMatch());
        dispatch(clearCurrentTime());
        navigation('/match-setup');
    };

    return (
        <Main
            showHeader={false}
            className="begin-game"
            title={<div>Welcome</div>}
            headerTitle={<div>BeginGame</div>}
            floatingHeader={false}
            showFooter={false}>
            <div className="logo-wrap">
                <img src={logoSrc} alt="logo" className="main-logo" />
            </div>
            <div className="content-wrap">
                <h2 className="h-1 title-style primary-color">Welcome to KickStats!</h2>
                <div className="content-shadow-box shadow-ctn">
                    <p>The ultimate app for football commentary and player analysis.
                        Record live match commentary, and our advanced AI will convert your speech to text,
                        generating game event tags. You&apos;ll receive a breakdown of all match statistics via email once your report is submitted. Read more on our <a href="https://www.kickstats.co.uk" target="_blank" rel="noreferrer">website!</a>
                    </p>
                </div>
                <div className="content-shadow-box shadow-ctn">
                    <p>Please watch our instruction video before you begin.</p>
                    <span>
                        <RightArrowIcon />
                        <a href="https://www.youtube.com/watch?v=Nkv7N5PlLqE" target="_blank" rel="noreferrer">Video Guide</a>
                    </span>
                    <p>If you have any questions, don&apos;t hesitate to contact us via the email below!</p>
                    <span>
                        <EmailIcon />
                        <a href="mailto:teamhq@txid.co.uk">teamhq@txid.co.uk</a>
                    </span>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <Button onClick={handleBeginGame} type="primary" shape="round" size="large" htmlType="submit" block className="primary-btn-style mt-2">
                        Begin Game
                    </Button>
                </div>
            </div>
        </Main>
    );
};

export default Dashboard;

Dashboard.propTypes = {};
