import React, { useEffect, useRef } from 'react';

import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { DeleteIcon, LoadingIcon } from '../../components/Icons';
import { deleteEvent, getEvents } from '../../core/event/eventApi';
import { AppDispatch, CombinedStates } from '../../core/types';
import formatTime from '../../core/utils/helpers';
import './matchLog.scss';

import usePolling from './usePolling';

enum EventStatus {
    CREATED = 'created',
    PROCESSING = 'processing',
    COMPLETED = 'completed',
}

interface EventsListingProps {
    matchId: string;
}

const EventsListing: React.FC<EventsListingProps> = ({ matchId }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { events, isLoading, deleteEventIds } = useSelector((state: CombinedStates) => state.event);
    const loadingRef = useRef(isLoading);

    // Update loadingRef whenever loading state changes
    useEffect(() => {
        loadingRef.current = isLoading;
    }, [isLoading]);

    // Fetch initial events
    useEffect(() => {
        dispatch(getEvents({ match_id: matchId, status: null }));
    }, []);

    // Polling for in-progress events
    usePolling(() => {
        if (!loadingRef.current) {
            dispatch(getEvents({ match_id: matchId, status: null }));
        }
    }, 2000);

    const handleEventDelete = (eventId: string) => {
        dispatch(deleteEvent(eventId));
    };

    if (!events?.length) {
        return (
            <div className="content-box-wrapper">
                <div className="content-shadow-box shadow-ctn">
                    <p>
                        Events will appear here as you finish each recording. Begin by recording the starting lineup.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="content-box-wrapper">
            {events?.map((event) => (
                <div className="content-shadow-box shadow-ctn" key={`match-event${event?.id}`}>
                    <span className="content-box-top">
                        <span className="content-time primary-color">{formatTime(event?.occurred_at)}</span>
                        {
                            event?.status === EventStatus.CREATED && !event.summary ? (
                                <span className="content-process-txt primary-color">
                                    <LoadingIcon />
                                    <p>
                                        Processing audio
                                    </p>
                                </span>
                            ) : null
                        }
                        <span className="content-icon">
                            <Button
                                type="text"
                                className="delete-icon-btn"
                                onClick={() => handleEventDelete(event?.id?.toString())}
                                disabled={!!deleteEventIds?.includes(event?.id?.toString())}>
                                <DeleteIcon />
                            </Button>
                        </span>
                    </span>
                    {event?.summary && <p>{event.summary}</p>}
                </div>
            ))}
        </div>
    );
};

export default EventsListing;
