import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { createEvent, deleteEvent, getEvents, clearEvents } from './eventApi';
import { EventState } from './eventTypes';

const initialState = {
    error: null,
    isLoading: false,
    events: [],
    deleteEventIds: [],
} as EventState;

/* eslint-disable no-param-reassign */
export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        addDeleteEventId: (state, action: PayloadAction<string>) => {
            if (state.deleteEventIds?.length) {
                state.deleteEventIds.push(action.payload);
            } else {
                state.deleteEventIds = [action.payload];
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createEvent.fulfilled, (state:EventState) => {
            state.isLoading = false;
            state.error = null;
        });

        builder.addCase(getEvents.fulfilled, (state: EventState, action) => {
            state.isLoading = false;
            state.error = null;
            state.events = action.payload.events;
        });

        builder.addCase(deleteEvent.fulfilled, (state:EventState, action) => {
            const eventId = action.meta.arg;

            if (state.deleteEventIds) state.deleteEventIds.push(eventId);

            state.error = null;
            state.events = state.events?.filter((event) => event.id.toString() !== eventId) ?? [];
        });

        builder.addCase(clearEvents.fulfilled, (state) => {
            state.isLoading = false;
            state.error = null;
            state.events = [];
        });

        // Pending state for all requests
        builder.addMatcher(
            isAnyOf(
                createEvent.pending,
                getEvents.pending,
                deleteEvent.pending,
                clearEvents.pending,
            ),
            (state:EventState) => {
                state.isLoading = true;
                state.error = null;
            },
        );

        // Error state for all requests
        builder.addMatcher(
            isAnyOf(
                createEvent.rejected,
                getEvents.rejected,
                deleteEvent.rejected,
                clearEvents.rejected,
            ),
            (state:EventState, action) => {
                state.isLoading = false;
                state.error = action.error;
            },
        );
    },
});
/* eslint-enable no-param-reassign */

export default eventSlice.reducer;

export const { addDeleteEventId } = eventSlice.actions;
