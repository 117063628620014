import React, { useEffect, useState } from 'react';

import { Button, Form, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { AppDispatch, CombinedStates } from '@/core/types';

import { LeftArrowIcon } from '../../components/Icons';
import Main from '../../components/layout/Main';
import { updateMatch } from '../../core/match/matchApi';

import './reviewScore.scss';
import { valuesProps } from './types';

const ReviewScore = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { matchData, isLoading, error } = useSelector((state: CombinedStates) => state.match);
    const [form] = Form.useForm();
    const navigation = useNavigate();
    const [isFormValid, setIsFormValid] = useState(false);
    const formValues = Form.useWatch([], form);

    useEffect(() => {
        form
            .validateFields({ validateOnly: true })
            .then(() => setIsFormValid(true))
            .catch(() => setIsFormValid(false));
    }, [form, formValues]);

    useEffect(() => {
        if (!isLoading && isFormValid && !error) {
            navigation('/confirmation');
        }
    }, [isLoading]);

    const onFinish = (values: valuesProps) => {
        if (matchData && matchData?.id) {
            dispatch(updateMatch({
                ...values,
                match_id: matchData.id,
            }));
        }
    };

    const backLink = (
        <Button type="text" onClick={() => navigation('/match')}>
            <LeftArrowIcon />
        </Button>
    );

    const footerContent = (
        <Button onClick={form.submit} type="primary" shape="round" size="large" block className="primary-btn-style" loading={isLoading} disabled={!isFormValid}>
            Submit
        </Button>
    );

    return (
        <Main
            showHeader
            className="review-score"
            title={<div>Review Score</div>}
            headerTitle={<div>Review Score</div>}
            afterHeader={<p>Please update the score below before submitting your match data.</p>}
            floatingHeader={false}
            backLink={backLink}
            showFooter
            floatingFooter
            footerContent={footerContent}>
            <div className="content-wrap">
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical">

                    <Form.Item
                        label="Own Team"
                        name="own_team_score"
                        rules={[
                            { required: true, message: 'Please enter your team score' }]}>
                        <InputNumber min={0} max={100000} placeholder="Type your teams score here" />
                    </Form.Item>
                    <Form.Item
                        label="Opponent"
                        name="opponent_score"
                        rules={[
                            { required: true, message: 'Please enter opponent’s team score' }]}>

                        <InputNumber min={0} max={100000} placeholder="Type the opponents score here" />
                    </Form.Item>
                </Form>
            </div>
            <div />
        </Main>
    );
};

export default ReviewScore;

ReviewScore.propTypes = {};
