/* eslint-disable react/no-invalid-html-attribute */
import React from 'react';

import { Layout } from 'antd';
import Helmet from 'react-helmet';

import favicon114 from '../../../assets/logos/114x114.png';
import favicon120 from '../../../assets/logos/120x120.png';
import favicon128 from '../../../assets/logos/128x128.png';
import favicon144 from '../../../assets/logos/144x144.png';
import favicon152 from '../../../assets/logos/152x152.png';
import favicon16 from '../../../assets/logos/16x16.png';
import favicon180 from '../../../assets/logos/180x180.png';
import favicon192 from '../../../assets/logos/192x192.png';
import favicon32 from '../../../assets/logos/32x32.png';
import favicon384 from '../../../assets/logos/384x384.png';
import favicon512 from '../../../assets/logos/512x512.png';
import favicon57 from '../../../assets/logos/57x57.png';
import favicon60 from '../../../assets/logos/60x60.png';
import favicon72 from '../../../assets/logos/72x72.png';
import favicon76 from '../../../assets/logos/76x76.png';
import favicon96 from '../../../assets/logos/96x96.png';
import constants from '../../../core/constants';
import BreadcrumbNav from '../BreadcrumbNav';
import Footer from '../Footer';
import Header from '../Header';
import './main.css';

import { MainProps } from './types';

const {
    APP_NAME,
} = constants;

const Main = (props: MainProps) => {

    const {
        children,
        title,
        breadcrumb,
        className,
        menu,
        backLink,
        showHeader,
        beforeHeader,
        afterHeader,
        floatingHeader,
        headerTitle,
        showFooter,
        beforeFooter,
        afterFooter,
        floatingFooter,
        footerContent,
        menuPlacement,
        menuWidth,
    } = props;

    return (
        <div className={`page-wrap ${className}`}>
            <Helmet
                title={typeof title === 'object' ? title.props.children : title}
                titleTemplate={`%s - ${APP_NAME}`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${APP_NAME} website` },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="apple-touch-icon-precomposed" type="image/png" href={favicon512} data-rh="true" />
                <link rel="apple-touch-icon-precomposed"
                    type="image/png"
                    href={favicon57}
                    sizes="57x57"
                    data-rh="true" />
                <link rel="apple-touch-icon-precomposed"
                    type="image/png"
                    href={favicon60}
                    sizes="60x60"
                    data-rh="true" />
                <link rel="apple-touch-icon-precomposed"
                    type="image/png"
                    href={favicon76}
                    sizes="76x76"
                    data-rh="true" />
                <link rel="apple-touch-icon-precomposed"
                    type="image/png"
                    href={favicon114}
                    sizes="114x114"
                    data-rh="true" />
                <link rel="apple-touch-icon-precomposed"
                    type="image/png"
                    href={favicon120}
                    sizes="120x120"
                    data-rh="true" />
                <link rel="apple-touch-icon-precomposed"
                    type="image/png"
                    href={favicon128}
                    sizes="128x128"
                    data-rh="true" />
                <link rel="apple-touch-icon-precomposed"
                    type="image/png"
                    href={favicon144}
                    sizes="144x144"
                    data-rh="true" />
                <link rel="apple-touch-icon-precomposed"
                    type="image/png"
                    href={favicon152}
                    sizes="152x152"
                    data-rh="true" />
                <link rel="apple-touch-icon-precomposed"
                    type="image/png"
                    href={favicon180}
                    sizes="180x180"
                    data-rh="true" />
                <link rel="apple-touch-icon-precomposed"
                    type="image/png"
                    href={favicon192}
                    sizes="192x192"
                    data-rh="true" />
                <link rel="icon" type="image/png" href={favicon32} sizes="36x36" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon57} sizes="57x57" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon60} sizes="60x60" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon72} sizes="72x72" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon76} sizes="76x76" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon96} sizes="96x96" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon114} sizes="114x114" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon120} sizes="120x120" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon128} sizes="128x128" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon144} sizes="144x144" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon152} sizes="152x152" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon180} sizes="180x180" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon192} sizes="192x192" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon384} sizes="384x384" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon512} sizes="512x512" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon16} sizes="16x16" data-rh="true" />
                <link rel="icon" type="image/png" href={favicon32} sizes="32x32" data-rh="true" />
            </Helmet>
            {showHeader
                ? (
                    <Header
                        afterHeader={afterHeader}
                        beforeHeader={beforeHeader}
                        floating={floatingHeader}
                        title={headerTitle || title}
                        backLink={backLink}
                        menu={menu}
                        menuPlacement={menuPlacement}
                        menuWidth={menuWidth} />
                )
                : null}
            <Layout.Content className="main-content">
                {breadcrumb ? <BreadcrumbNav navigation={breadcrumb} /> : null}
                {children}
            </Layout.Content>
            {showFooter
                ? (
                    <Footer
                        beforeFooter={beforeFooter ?? <div />}
                        afterFooter={afterFooter ?? <div />}
                        floating={floatingFooter ?? false}>
                        {footerContent ?? <div />}
                    </Footer>
                )
                : null}
        </div>
    );
};

Main.defaultProps = {
    afterFooter: null,
    afterHeader: null,
    beforeFooter: null,
    beforeHeader: null,
    logo: '',
    showFooter: true,
    showHeader: true,
};

export default Main;
