import React from 'react';

import Helmet from 'react-helmet';

import './notFound.scss';

const NotFound = () => (
    <div className="ui container not-found">
        <Helmet title="Not found" />
        <div className="ui icon message">
            <i className="warning sign icon" />
            <div className="content">
                <div className="header">
                    Page not found
                </div>
                <p>The page you are looking for does not exist.</p>
            </div>
        </div>
    </div>
);

export default NotFound;
