import { useEffect, useRef } from 'react';

const usePolling = (callback: () => void, interval: number) => {
    const intervalRef = useRef<number | null>(null); // Corrected type
    const callbackRef = useRef(callback);
    const isPageVisible = useRef<boolean>(true);

    // Always have the latest callback
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    // Start polling if not already started and the page is visible
    const startPolling = () => {
        if (intervalRef.current === null && isPageVisible.current) {
            intervalRef.current = window.setInterval(() => {
                callbackRef.current();
            }, interval);
        }
    };

    // Stop polling if it's running
    const stopPolling = () => {
        if (intervalRef.current !== null) {
            window.clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    // Handle visibility changes
    useEffect(() => {
        if (typeof document === 'undefined') return; // Handle SSR if applicable

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                isPageVisible.current = true;
                startPolling();
            } else {
                isPageVisible.current = false;
                stopPolling();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Initial check in case the tab is already hidden or visible
        handleVisibilityChange();
        // eslint-disable-next-line consistent-return
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [interval]); // Include 'interval' if you expect it to change

    // Initialize polling when the component mounts
    useEffect(() => {
        startPolling();

        // Cleanup on unmount
        return () => {
            stopPolling();
        };
    }, [interval]); // Include 'interval' if you expect it to change
};

export default usePolling;
