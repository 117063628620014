import React, { FC } from 'react';

import ResetPasswordForm from '../../components/Form/ResetPasswordForm';
import '../Login/login.scss';
import AuthLayout from '../../components/layout/AuthLayout';

import { ResetPasswordProps } from './types';

const ResetPassword:FC<ResetPasswordProps> = ({ match }) => {

    const backLink = {
        link: '/',
        title: '',
    };

    // Probably need a function to check the reset code first before showing the form
    console.log(match);

    return (
        <AuthLayout>
            <ResetPasswordForm backLink={backLink} />
        </AuthLayout>
    );
};

export default ResetPassword;
