import React, { FC } from 'react';

import ConfirmationScreen from '../pages/ConfirmationScreen';
import Dashboard from '../pages/Dashboard';
import ForgetPasswordPage from '../pages/ForgetPassword';
import Login from '../pages/Login';
import MatchLog from '../pages/MatchLog';
import MatchSetup from '../pages/MatchSetup';
import ResetPassword from '../pages/ResetPassword';
import ReviewScore from '../pages/ReviewScore';
import TwoFactorAuth from '../pages/TwoFactorAuth';

import { CoreRouter, LogoutRoute } from './utils/coreRouter';

const Routes: FC = () => (
    <CoreRouter routes={[
        { path: '/', component: Login, type: 'default' },
        { path: '/forget-password', component: ForgetPasswordPage, type: 'default' },
        { path: '/dashboard', component: Dashboard, type: 'auth' },
        { path: '/match-setup', component: MatchSetup, type: 'auth' },
        { path: '/match', component: MatchLog, type: 'auth' },
        { path: '/review-score', component: ReviewScore, type: 'auth' },
        { path: '/confirmation', component: ConfirmationScreen, type: 'auth' },
        { path: '/reset-password/:token/:email', component: ResetPassword, type: 'default' },
        { path: '/two-factor', component: TwoFactorAuth, type: 'default' },
        { path: '/logout', component: LogoutRoute, type: 'default' },
        { path: '*', component: Login, type: 'default' },
    ]} />
);

export default Routes;
