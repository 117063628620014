import React, { useEffect, useState } from 'react';

import { Button, Form, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { AppDispatch, CombinedStates } from '@/core/types';

import { ArrowDownIcon, LeftArrowIcon } from '../../components/Icons';
import Main from '../../components/layout/Main';
import { setupMatch } from '../../core/match/matchApi';

import './matchSetup.scss';
import { AgeGroupOptions, MatchLengthOptions, MatchFormatOptions, MatchTypeOptions, GenderOptions } from './constants';
import { valuesProps } from './types';

const MatchSetup = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { userData } = useSelector((state: CombinedStates) => state.user);
    const { matchData, isLoading } = useSelector((state: CombinedStates) => state.match);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isFormValid, setIsFormValid] = useState(false);
    const [isMatchTypeOther, setIsMatchTypeOther] = useState(false);
    const formValues = Form.useWatch([], form);

    useEffect(() => {
        form
            .validateFields({ validateOnly: true })
            .then(() => setIsFormValid(true))
            .catch(() => setIsFormValid(false));
    }, [form, formValues]);

    useEffect(() => {
        if (!isLoading && matchData) {
            navigate('/match');
        }
    }, [isLoading, matchData]);

    const onFinish = (values: valuesProps) => {
        const payload = {
            ...values,
            scheduled_duration: values.match_length,
            user_id: userData.id,
        };

        // remove the age group, format and match length value from payload
        if (isMatchTypeOther) {
            payload.age_group = '';
            payload.format = '';
            payload.scheduled_duration = '';
        }

        dispatch(setupMatch(payload));
    };

    const handleChange = (value: string) => {
        if (value === 'other') {
            form.setFieldsValue({
                ...formValues,
                type: value,
            });
            form.resetFields(['format', 'age_group', 'match_length']);
            setIsMatchTypeOther(true);
        } else if (isMatchTypeOther) {
            setIsMatchTypeOther(false);
        }
    };

    const backLink = (
        <Button type="text" onClick={() => navigate('/dashboard')}>
            <LeftArrowIcon />
        </Button>
    );

    const footerContent = (
        <Button onClick={form.submit} type="primary" shape="round" size="large" block className="primary-btn-style" loading={isLoading} disabled={!isFormValid}>
            Continue
        </Button>
    );

    return (
        <Main
            showHeader
            className="match-setup"
            title={<div>Match Setup</div>}
            headerTitle={<div>Match Setup</div>}
            floatingHeader={false}
            backLink={backLink}
            showFooter
            floatingFooter
            footerContent={footerContent}>
            <div className="content-wrap">
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical">
                    <Form.Item
                        label="Match Type"
                        name="type"
                        rules={[{ required: true, message: 'Please select a match type' }]}>
                        <Select
                            size="large"
                            placeholder="Select a Match Type"
                            suffixIcon={<ArrowDownIcon />}
                            onChange={handleChange}
                            options={MatchTypeOptions} />
                    </Form.Item>

                    <Form.Item
                        label="Match Format"
                        name="format"
                        rules={[{ required: !isMatchTypeOther, message: 'Please select a format' }]}>
                        <Select
                            size="large"
                            placeholder="Select Format"
                            suffixIcon={<ArrowDownIcon />}
                            disabled={isMatchTypeOther}
                            options={MatchFormatOptions} />
                    </Form.Item>

                    <Form.Item
                        label="Age Group"
                        name="age_group"
                        rules={[{ required: !isMatchTypeOther, message: 'Please select an age group' }]}>
                        <Select
                            size="large"
                            placeholder="Select an Age Group"
                            suffixIcon={<ArrowDownIcon />}
                            disabled={isMatchTypeOther}
                            options={AgeGroupOptions} />
                    </Form.Item>

                    <Form.Item
                        label="Gender"
                        name="gender"
                        rules={[{ required: true, message: 'Please select a gender' }]}>
                        <Select
                            size="large"
                            placeholder="Select a Gender"
                            suffixIcon={<ArrowDownIcon />}
                            options={GenderOptions} />
                    </Form.Item>

                    <Form.Item
                        label="Match Length"
                        name="match_length"
                        rules={[{ required: !isMatchTypeOther, message: 'Please select length' }]}>
                        <Select
                            size="large"
                            placeholder="Select Length"
                            suffixIcon={<ArrowDownIcon />}
                            disabled={isMatchTypeOther}
                            options={MatchLengthOptions} />
                    </Form.Item>

                    <Form.Item
                        label="Own Team"
                        name="own_team"
                        rules={[
                            { required: true, message: 'Please enter your team name' },
                            { max: 50, message: 'Team name can’t be longer than 50 characters' },
                            { pattern: /^[a-zA-Z0-9 ]*$/, message: 'Team name can only include letters, numbers and spaces' }]}>
                        <Input placeholder="Enter Your Team Name" />
                    </Form.Item>
                    <Form.Item
                        label="Opponent"
                        name="opponent"
                        rules={[
                            { required: true, message: 'Please enter opponent’s team name' },
                            { max: 50, message: 'Team name can’t be longer than 50 characters' },
                            { pattern: /^[a-zA-Z0-9 ]*$/, message: 'Team name can only include letters, numbers and spaces' }]}>
                        <Input placeholder="Enter Opponent’s Team Name" />
                    </Form.Item>
                </Form>
            </div>
            <div />
        </Main>
    );
};

export default MatchSetup;

MatchSetup.propTypes = {};
