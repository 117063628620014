import React, { useMemo, useState } from 'react';

import { notification } from 'antd';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '@/core/types';

import { createEvent } from '../../core/event/eventApi';
import { MicIcon } from '../Icons';

import useAudioRecorder, { RecordingState } from './useAudioRecorder';

import 'react-circular-progressbar/dist/styles.css';
import './styles.scss';

interface RecordingButtonProps {
    matchId: number;
}

const RecordingButton: React.FC<RecordingButtonProps> = ({ matchId }: RecordingButtonProps) => {
    const dispatch = useDispatch<AppDispatch>();

    const handleAudioCallback = async (file: File, fallbackSummary: string, duration: number) => {
        try {
            await dispatch(createEvent({
                match_id: matchId,
                duration,
                audio_file: file,
                fallback_summary: fallbackSummary,
            }));
        } catch (error) {
            console.error('Error creating event:', error);
        }
    };

    const { isRecording, startRecording, stopRecording, requestPermission, recordingState, hasPermission } = useAudioRecorder({ handleAudioCallback });

    const renderSpinner = () => {
        if (recordingState === RecordingState.RECORDING) {
            return (
                <CircularProgressbar
                    value={100}
                    styles={buildStyles({
                        pathColor: '#80B4A6',
                        trailColor: 'rgb(244, 247, 246)',
                        strokeLinecap: 'butt',
                    })} />
            );
        }

        if (recordingState === RecordingState.STOPPING || recordingState === RecordingState.PENDING) {
            return (
                <div className="spinner">
                    <CircularProgressbar
                        value={17.5}
                        styles={buildStyles({
                            pathColor: 'rgba(7, 109, 82, 1)',
                            trailColor: 'rgb(244, 247, 246)',
                            strokeLinecap: 'butt',
                        })} />
                </div>
            );
        }

        return null;
    };

    const handleDown = async () => {
        if (!hasPermission) {
            const granted = await requestPermission();

            if (!granted) {
                notification.error({
                    message: 'Permission required',
                    description: 'Microphone access was denied. Please enable it in your browser settings.',
                });
            }
        } else if (!isRecording) {
            startRecording();
        }
    };

    const handleRelease = () => {
        setTimeout(() => {
            stopRecording();
        }, 600);
    };

    const ButtonColour = useMemo(() => {
        if (!hasPermission) {
            return '#cfcfcf';
        }

        if (isRecording) {
            return '#fff';
        }

        return '#076D52';
    }, [hasPermission, isRecording]);

    const BottomText = useMemo(() => {
        if (!hasPermission) {
            return 'Permission required. Click the record button once to enable it.';
        }

        if (isRecording) {
            return 'Recording';
        }

        return 'Hold to record';
    }, [hasPermission, isRecording]);

    return (
        <div className="recording-button-wrapper">
            <div
                onMouseLeave={handleRelease}
                onTouchCancel={handleRelease}
                className={`recording-button-holder ${isRecording ? 'recording' : 'hold-to-record'}`}>
                <div className="progress-indicator">
                    {renderSpinner()}
                </div>
                <button
                    aria-label="Record"
                    type="button"
                    className={`recording-button ${isRecording ? 'recording' : 'hold-to-record'}`}
                    onPointerDown={handleDown}
                    onPointerUp={handleRelease}>
                    <div className="icon">
                        <MicIcon f={ButtonColour} />
                    </div>
                </button>
            </div>
            <p>{BottomText}</p>
        </div>
    );
};

export default RecordingButton;
