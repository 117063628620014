import React, { FC, useEffect, useState } from 'react';

import { Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppDispatch, CombinedStates } from '../../../core/types';
import { forgotPassword } from '../../../core/user/userApi';

import './forgetPasswordForm.scss';
import { FinishedFailedProps, valuesProps } from './types';

const ForgetPasswordForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [isFormValid, setIsFormValid] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const {
        isFetching,
        error,
    } = useSelector((state: CombinedStates) => state.user);

    const [form] = Form.useForm();
    const formValues = Form.useWatch([], form);

    useEffect(() => {
        form
            .validateFields({ validateOnly: true })
            .then(() => setIsFormValid(true))
            .catch(() => setIsFormValid(false));
    }, [form, formValues]);

    const onFinish = (values: valuesProps) => {
        dispatch(forgotPassword({
            email: values.email,
        }));
        setIsFormSubmitted(true);
    };

    const onFinishFailed: FC<FinishedFailedProps> = ({ errorFields }) => {
        form.scrollToField(errorFields[0]?.name);
        return null;
    };

    const handleOnClickCancel = (() => {
        navigate('/');
    });

    useEffect(() => {
        if (isFormSubmitted && error == null && !isFetching) {
            navigate('/');
        }
    }, [isFetching, error, isFormSubmitted]);

    return (
        <div className="login-form-box">
            <div className="form">
                <h3 className="form-title title-style h-1 primary-color">Forgot password</h3>
                <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical">
                    <Form.Item
                        label="Email"
                        name="email"
                        validateTrigger="onBlur"
                        rules={[{ type: 'email', message: 'The input is not a valid email!' }, { required: true, message: 'Please enter your email.' }]}>
                        <Input placeholder="Enter your email" autoComplete="username" />
                    </Form.Item>

                    <Form.Item>

                        {isFetching ? (
                            <Button type="primary" shape="round" size="large" htmlType="submit" block className="mt-2" loading disabled={!isFormValid}>
                                Reset my password
                            </Button>
                        ) : (
                            <Button type="primary" shape="round" size="large" htmlType="submit" block className="primary-btn-style mt-2" disabled={!isFormValid}>
                                Reset my password
                            </Button>
                        )}
                        <Button type="default" shape="round" size="large" htmlType="button" block className="mt-2" onClick={handleOnClickCancel}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default ForgetPasswordForm;
