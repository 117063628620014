import { useState, useRef, useEffect } from 'react';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

export enum RecordingState {
    PERMISSION_DENIED = 'permission_denied',
    IDLE = 'idle',
    PENDING = 'pending',
    RECORDING = 'recording',
    STOPPING = 'stopping',
}

interface UseAudioRecorder {
    handleAudioCallback: (file: File, fallbackSummary: string, duration: number) => void;
}

const MIN_RECORDING_DURATION = 500; // Minimum recording duration in milliseconds

function isIosSafari() {
    return (
        typeof navigator !== 'undefined'
        && navigator.userAgent.match(/(iPad|iPhone|iPod)/i)
        && navigator.userAgent.match(/AppleWebKit/i)
        && !navigator.userAgent.match(/CriOS/i)
    );
}

function handleError(err: unknown) {
    if (err instanceof DOMException) {
        if (err.name === 'NotAllowedError') {
            // eslint-disable-next-line no-alert
            alert('Microphone access was denied. Please enable it in your browser settings.');
        } else if (err.name === 'NotFoundError') {
            // eslint-disable-next-line no-alert
            alert('No microphone found. Please ensure your microphone is connected.');
        } else {
            // eslint-disable-next-line no-alert
            alert('An error occurred while trying to access the microphone.');
        }
    } else {
        // eslint-disable-next-line no-alert
        alert('An unexpected error occurred.');
    }
}

const useAudioRecorder = ({ handleAudioCallback }: UseAudioRecorder) => {
    const [isRecording, setIsRecording] = useState(false);
    const [recordingState, setRecordingState] = useState<RecordingState>(RecordingState.IDLE);
    const [recordingStartTime, setRecordingStartTime] = useState<number | null>(null);
    const [hasPermission, setHasPermission] = useState<boolean>(false);

    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunksRef = useRef<Blob[]>([]);
    const abortControllerRef = useRef<AbortController | null>(null);
    const recordingStateRef = useRef(recordingState);

    const {
        transcript,
        resetTranscript,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition();

    const cleanupMediaRecorder = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.ondataavailable = null;
            mediaRecorderRef.current.onstop = null;
            mediaRecorderRef.current.onerror = null;
            mediaRecorderRef.current.onstart = null;
            if (mediaRecorderRef.current.state !== 'inactive') {
                mediaRecorderRef.current.stop();
            }
            mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
            mediaRecorderRef.current = null;
        }
    };

    const startListening = () => {
        if (browserSupportsSpeechRecognition) {
            SpeechRecognition.startListening({ continuous: true });
        }
    };
    const stopListening = () => {
        if (browserSupportsSpeechRecognition) {
            SpeechRecognition.stopListening();
        }
    };

    const resetRecordingState = () => {
        setRecordingState(RecordingState.IDLE);
        setIsRecording(false);
        resetTranscript();
    };

    async function getBestAudioStream() {
        const audioConstraints = {
            sampleRate: 44100,
            sampleSize: 16,
            channelCount: 1,
            echoCancellation: true,
            noiseSuppression: true,
        };

        const filteredAudioConstraints = Object.fromEntries(
            Object.entries(audioConstraints).filter(([, value]) => value !== undefined),
        );

        const stream = await navigator.mediaDevices.getUserMedia({
            audio: {
                ...filteredAudioConstraints,
            },
            video: false,
        });

        return stream;
    }

    const requestPermission = async () => {
        try {
            const stream = await getBestAudioStream();
            stream.getTracks().forEach(track => track.stop());
            setHasPermission(true);
            setRecordingState(RecordingState.IDLE);
            return true;
        } catch (err) {
            handleError(err);
            setHasPermission(false);
            return false;
        }
    };

    const stopRecording = () => {
        if (recordingState === RecordingState.IDLE) {
            return;
        }
        setRecordingState(RecordingState.STOPPING);
    };

    const setupMediaRecorder = async (abortSignal?: AbortSignal) => {
        if (mediaRecorderRef.current) {
            return;
        }

        try {
            resetTranscript();
            startListening();
            setRecordingStartTime(Date.now());
            audioChunksRef.current = [];

            const stream = await getBestAudioStream();

            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            // Check if the operation was aborted or user no longer wants to record
            if (abortSignal?.aborted) {
                console.log('Recording setup aborted');
                stream.getTracks().forEach(track => track.stop());
                setRecordingState(RecordingState.IDLE);
            }

        } catch (err) {
            console.log('onerror');
            handleError(err);
            cleanupMediaRecorder();
            resetRecordingState();
        }
    };

    const startRecording = async () => {
        if (recordingState !== RecordingState.IDLE) {
            return;
        }

        setRecordingState(RecordingState.PENDING);
    };

    /**
     * Handle the recording state
     * If the recording state is stopping and there are audio chunks, create a file and send it to the server
     * If the duration is greater than the minimum recording duration, send the audio to the server
     * Reset the audio chunks and recording state
     */
    useEffect(() => {
        recordingStateRef.current = recordingState;

        const handleState = async () => {
            switch (recordingState) {

            case RecordingState.PENDING:
                await setupMediaRecorder();

                if (mediaRecorderRef.current && recordingStateRef.current === RecordingState.PENDING) {
                    setRecordingState(RecordingState.RECORDING);
                    setIsRecording(true);
                } else {
                    cleanupMediaRecorder();
                    resetRecordingState();
                }
                break;
            case RecordingState.RECORDING:
                if (mediaRecorderRef.current) {
                    mediaRecorderRef.current.start(100);
                }
                break;
            case RecordingState.STOPPING:
                if (audioChunksRef.current.length > 0) {
                    const recordingStopTime = Date.now();
                    const duration = recordingStopTime && recordingStartTime ? recordingStopTime - recordingStartTime : 0;
                    const mimeType = isIosSafari() ? 'audio/mp4' : 'audio/webm; codecs=opus';
                    const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });

                    const fileExtension = mimeType.split('/')[1];
                    const file = new File([audioBlob], `audio.${fileExtension}`, { type: mimeType });

                    if (duration >= MIN_RECORDING_DURATION && transcript) {
                        handleAudioCallback(file, transcript, duration);
                    }

                    audioChunksRef.current = [];
                }

                abortControllerRef.current?.abort();
                stopListening();
                resetRecordingState();
                cleanupMediaRecorder();
                break;
            case RecordingState.IDLE:
            default:
                break;
            }
        };

        handleState();
    }, [recordingState]);

    /**
     * Listen for visibility change events and stop recording if the tab is hidden
     */
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (
                document.visibilityState === 'hidden'
                && (recordingStateRef.current === RecordingState.RECORDING
                  || recordingStateRef.current === RecordingState.PENDING)
            ) {
                stopRecording();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    /**
     * Check if the user has microphone permissions
     * If the browser supports the Permissions API, check the permission status
     * If the browser does not support the Permissions API, try to get the user media stream
     * If the user denies permission, set hasPermission to false
     */
    useEffect(() => {
        let isMounted = true;

        const updatePermissionStatus = (status: PermissionStatus) => {
            if (isMounted) {
                setHasPermission(status.state === 'granted');
            }
        };

        if (navigator.permissions) {
            navigator.permissions.query({ name: 'microphone' as PermissionName })
                .then((status) => {
                    updatePermissionStatus(status);
                })
                .catch(() => {
                    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
                        .then((stream) => {
                            if (isMounted) {
                                stream.getTracks().forEach(track => track.stop());
                                setHasPermission(true);
                            }
                        })
                        .catch(() => {
                            setHasPermission(false);
                        });
                });
        } else {
            navigator.mediaDevices.getUserMedia({ audio: true, video: false })
                .then((stream) => {
                    if (isMounted) {
                        stream.getTracks().forEach(track => track.stop());
                        setHasPermission(true);
                    }
                })
                .catch(() => {
                    setHasPermission(false);
                });
        }

        return () => {
            isMounted = false;
        };
    }, []);

    /**
     * Stop the media recorder and reset the audio chunks
     * This is a cleanup function that is called when the component is unmounted
     */
    useEffect(() => {
        return () => {
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stop();
                mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
            }
            mediaRecorderRef.current = null;
            audioChunksRef.current = [];
        };
    }, []);

    return { isRecording, recordingState, requestPermission, startRecording, stopRecording, mediaRef: mediaRecorderRef.current, hasPermission };
};

export default useAudioRecorder;
