import React, { useEffect, useState } from 'react';

import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentTime } from '../../core/timer/timerSlice';
import { CombinedStates } from '../../core/types';
import formatTime from '../../core/utils/helpers';
import { PauseIcon, PlayIcon, RecordingStopIcon } from '../Icons';

import './matchTimer.scss';

interface MatchTimerProps {
    isActive: boolean;
    setIsActive: (isActive: boolean) => void;
    onStop: () => void;
}

const MatchTimer: React.FC<MatchTimerProps> = ({ isActive, setIsActive, onStop }) => {
    // Use selector to get the initial time from the Redux state
    const { currentTime } = useSelector((state: CombinedStates) => state.timer);
    const [time, setTime] = useState<number>(currentTime);

    const dispatch = useDispatch();

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (isActive) {
            interval = setInterval(() => {
                setTime((prevTime) => {
                    const newTime = Math.min(prevTime + 1, 5999); // Limit to 99:59 (5999 seconds)
                    dispatch(setCurrentTime(newTime));
                    return newTime;
                });
            }, 1000);
        } else if (interval) clearInterval(interval);

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isActive]);

    const handlePlayPause = () => {
        setIsActive(!isActive);
    };

    const handleStop = () => {
        setIsActive(false);
        onStop();
    };

    return (
        <div className="timer-wrapper shadow-ctn">
            <span className="title-style timer-text">{formatTime(time)}</span>
            <span className="timer-action-wrap">
                <Button type="text" onClick={handlePlayPause}>
                    {isActive ? <PauseIcon /> : <PlayIcon />}
                </Button>
                <Button
                    type="text"
                    className={`${!isActive && time === 0 ? 'wrap-svg-disabled' : ''}`}
                    disabled={time === 0 && !isActive}
                    onClick={handleStop}>
                    <RecordingStopIcon />
                </Button>
            </span>
        </div>
    );
};

export default MatchTimer;
