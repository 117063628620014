import React, { useEffect, useState } from 'react';

import { Layout } from 'antd';

import './footer.css';
import { FooterProps } from './types';

const Footer = ({
    children = null,
    floating = false,
    beforeFooter = '',
    afterFooter = '',
}: FooterProps) => {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const scrollFunction = () => {
            if (document.documentElement.offsetHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight) {
                setIsSticky(false);
            } else {
                setIsSticky(true);
            }
        };
        scrollFunction();
        document.addEventListener('scroll', scrollFunction);

        return () => document.removeEventListener('scroll', scrollFunction);
    }, []);

    return (
        <>
            {beforeFooter}
            <Layout.Footer className={`footer ${floating ? 'floating-footer' : ''} ${isSticky ? 'sticky' : ''}`}>
                <Layout.Content className="main-content">{children}</Layout.Content>
            </Layout.Footer>
            {afterFooter}
        </>
    );
};

export default Footer;
