import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { getMatch, setupMatch, updateMatch, clearMatch } from './matchApi';
import { MatchState } from './matchTypes';

const initialState = {
    error: null,
    isLoading: false,
    matchData: null,
} as MatchState;

/* eslint-disable no-param-reassign */
export const matchSlice = createSlice({
    name: 'match',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMatch.fulfilled, (state: MatchState, { payload }) => {
            state.isLoading = false;
            state.error = null;
            state.matchData = payload;
        });

        builder.addCase(setupMatch.fulfilled, (state: MatchState, { payload }) => {
            state.isLoading = false;
            state.error = null;
            state.matchData = payload;
        });

        builder.addCase(updateMatch.fulfilled, (state) => {
            state.isLoading = false;
            state.error = null;
        });

        builder.addCase(clearMatch.fulfilled, (state) => {
            state.isLoading = false;
            state.error = null;
            state.matchData = null;
        });

        // Pending state for all requests
        builder.addMatcher(
            isAnyOf(
                getMatch.pending,
                setupMatch.pending,
                updateMatch.pending,
                clearMatch.pending,
            ),
            (state:MatchState) => {
                state.isLoading = true;
                state.error = null;
            },
        );

        // Error state for all requests
        builder.addMatcher(
            isAnyOf(
                getMatch.rejected,
                setupMatch.rejected,
                updateMatch.rejected,
                clearMatch.rejected,
            ),
            (state:MatchState, action) => {
                state.isLoading = false;
                state.error = action.error;
            },
        );
    },
});
/* eslint-enable no-param-reassign */

export default matchSlice.reducer;
