/* eslint-disable max-len */
import React, { FC } from 'react';

/* eslint-disable react/require-default-props */
export interface IconProps {
    h?: number;
    w?: number;
    f?: string;
    rotate?: number;
}

export const RightArrowIcon: FC<IconProps> = ({ h, w, f }) => (
    <svg width={w || 18} height={h || 20} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 3.16012C0 0.719734 2.66079 -0.796895 4.77146 0.440435L16.4391 7.2803C18.5203 8.50036 18.5203 11.4997 16.4391 12.7197L4.77146 19.5596C2.66079 20.7969 0 19.2802 0 16.8399V3.16012Z"
            fill={f || '#076D52'} />
    </svg>
);

export const LeftArrowIcon: FC<IconProps> = ({ h, w, f }) => (
    <svg width={w || 27} height={h || 20} viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.16529 1.26437C9.50587 0.911877 10.0727 0.911877 10.4252 1.26437C10.7658 1.60495 10.7658 2.1718 10.4252 2.51159L4.03668 8.90013H25.118C25.6094 8.90092 26 9.29152 26 9.78295C26 10.2744 25.6094 10.6777 25.118 10.6777H4.03668L10.4252 17.0543C10.7658 17.4068 10.7658 17.9744 10.4252 18.3142C10.0727 18.6667 9.50508 18.6667 9.16529 18.3142L1.26437 10.4133C0.911877 10.0727 0.911877 9.50587 1.26437 9.16608L9.16529 1.26437Z" fill={f || '#076D52'} stroke={f || '#076D52'} />
    </svg>

);

export const EmailIcon: FC<IconProps> = ({ h, w, f }) => (
    <svg width={w || 20} height={h || 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_293)">
            <path d="M19.9617 4.61841L12.9467 11.6334C12.1645 12.4136 11.1048 12.8518 10 12.8518C8.89521 12.8518 7.83552 12.4136 7.05333 11.6334L0.0383333 4.61841C0.0266667 4.75007 0 4.86924 0 5.00008V15.0001C0.00132321 16.1047 0.440735 17.1638 1.22185 17.9449C2.00296 18.726 3.062 19.1654 4.16667 19.1667H15.8333C16.938 19.1654 17.997 18.726 18.7782 17.9449C19.5593 17.1638 19.9987 16.1047 20 15.0001V5.00008C20 4.86924 19.9733 4.75007 19.9617 4.61841Z"
                fill={f || '#076D52'} />
            <path d="M11.7683 10.4549L19.38 2.84242C19.0113 2.23102 18.4912 1.72495 17.87 1.37303C17.2487 1.0211 16.5473 0.835203 15.8333 0.833252H4.16666C3.45268 0.835203 2.75125 1.0211 2.13002 1.37303C1.50879 1.72495 0.988727 2.23102 0.619995 2.84242L8.23166 10.4549C8.7013 10.9227 9.33715 11.1853 10 11.1853C10.6628 11.1853 11.2987 10.9227 11.7683 10.4549Z"
                fill={f || '#076D52'} />
        </g>
        <defs>
            <clipPath id="clip0_1_293">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const ArrowDownIcon: FC<IconProps> = ({ h, w, f, rotate }) => (
    <svg width={w || 12} height={h || 8} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ rotate: `${rotate ?? 0}deg`, transition: 'all 0.3s' }}>
        <path d="M5.99998 7.42017C5.78492 7.42017 5.56988 7.33805 5.40591 7.17417L0.246185 2.01439C-0.0820413 1.68616 -0.0820413 1.154 0.246185 0.825904C0.574278 0.497811 1.10633 0.497811 1.43459 0.825904L5.99998 5.39156L10.5654 0.826063C10.8936 0.49797 11.4256 0.49797 11.7537 0.826063C12.0821 1.15416 12.0821 1.68632 11.7537 2.01455L6.59405 7.17433C6.43 7.33824 6.21497 7.42017 5.99998 7.42017Z" fill={f || '#80B4A6'} />
    </svg>

);

export const DeleteIcon: FC<IconProps> = ({ h, w, f }) => (
    <svg width={w || 16} height={h || 18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.00469 0.499929C5.00471 0.367514 5.05731 0.240527 5.15092 0.146872C5.24453 0.0532173 5.37149 0.000557537 5.50391 0.000464356L10.4958 0.000183105C10.6282 0.000490159 10.7551 0.0532958 10.8487 0.147026C10.9423 0.240756 10.9949 0.367763 10.995 0.500211V1.73479H5.00469V0.499929ZM13.2123 17.285C13.1996 17.4797 13.1129 17.6621 12.9701 17.795C12.8272 17.9279 12.639 18.0011 12.4439 17.9998H3.49838C3.30336 17.9992 3.11575 17.9249 2.97324 17.7918C2.83072 17.6587 2.74386 17.4766 2.73008 17.282L1.96459 6.08411H14.0293L13.2125 17.2849L13.2123 17.285ZM15.201 5.06993H0.798981V3.90977C0.799195 3.60207 0.921505 3.30704 1.13906 3.08944C1.35661 2.87184 1.65162 2.74947 1.95931 2.74919L14.0405 2.7488C14.3482 2.74928 14.6431 2.87176 14.8606 3.08938C15.0781 3.307 15.2004 3.602 15.2007 3.90966V5.06982L15.201 5.06993ZM5.68106 15.3303C5.68106 15.3969 5.69417 15.4628 5.71965 15.5243C5.74513 15.5859 5.78248 15.6418 5.82956 15.6888C5.87664 15.7359 5.93254 15.7733 5.99405 15.7987C6.05557 15.8242 6.1215 15.8373 6.18808 15.8373C6.25466 15.8373 6.3206 15.8242 6.38211 15.7987C6.44363 15.7733 6.49952 15.7359 6.5466 15.6888C6.59368 15.6418 6.63103 15.5859 6.65651 15.5243C6.68199 15.4628 6.6951 15.3969 6.6951 15.3303V8.22007C6.69404 8.08635 6.64018 7.95847 6.54527 7.86427C6.45035 7.77008 6.32207 7.7172 6.18834 7.71716C6.05462 7.71711 5.9263 7.7699 5.83132 7.86403C5.73634 7.95816 5.68239 8.086 5.68123 8.21972V15.3303H5.68106ZM9.29864 15.3303C9.29864 15.4648 9.35206 15.5938 9.44716 15.6889C9.54226 15.784 9.67124 15.8374 9.80573 15.8374C9.94022 15.8374 10.0692 15.784 10.1643 15.6889C10.2594 15.5938 10.3128 15.4648 10.3128 15.3303V8.22007C10.3118 8.08633 10.2579 7.95843 10.163 7.86422C10.068 7.77002 9.93973 7.71713 9.80599 7.71709C9.67225 7.71704 9.54391 7.76984 9.44892 7.86398C9.35392 7.95812 9.29997 8.08598 9.29881 8.21972L9.29864 15.3303Z" fill={f || '#80B4A6'} />
    </svg>
);

export const LoadingIcon: FC<IconProps> = ({ h, w, f }) => (
    <svg width={w || 17} height={h || 17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.40506 1V2.89873M8.40506 12.962V16M3.65823 8.40506H1M15.4304 8.40506H14.2911M13.3092 13.3092L12.7722 12.7722M13.4665 3.40439L12.3924 4.47848M3.02904 13.7811L5.17722 11.6329M3.18634 3.2471L4.79747 4.85823" stroke={f || '#076D52'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const MicIcon: FC<IconProps> = ({ f }) => (
    <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.4998 41.5375C33.6733 41.5375 36.3891 40.4076 38.6486 38.149C40.9078 35.8904 42.0375 33.1732 42.0375 29.9999V11.5388C42.0375 8.36553 40.9087 5.64947 38.6486 3.38971C36.3891 1.13045 33.6733 0 30.4998 0C27.3267 0 24.6105 1.13045 22.3509 3.38971C20.0911 5.64909 18.9614 8.36553 18.9614 11.5388V29.9999C18.9614 33.1731 20.0916 35.8904 22.3509 38.149C24.6101 40.4076 27.3267 41.5375 30.4998 41.5375Z" fill={f || '#076D52'} />
        <path d="M50.5832 23.762C50.1278 23.3052 49.5858 23.0768 48.9607 23.0768C48.3363 23.0768 47.7956 23.3052 47.3382 23.762C46.882 24.2187 46.6536 24.7595 46.6536 25.3843V29.9999C46.6536 34.4472 45.0727 38.251 41.9116 41.412C38.7514 44.5731 34.9471 46.1537 30.4997 46.1537C26.0524 46.1537 22.2485 44.5731 19.0872 41.412C15.9264 38.2519 14.346 34.4473 14.346 29.9999V25.3843C14.346 24.7595 14.1176 24.2187 13.661 23.762C13.2042 23.3052 12.6639 23.0768 12.0385 23.0768C11.4131 23.0768 10.8722 23.3052 10.4157 23.762C9.95875 24.2187 9.73041 24.7595 9.73041 25.3843V29.9999C9.73041 35.3124 11.5036 39.934 15.0489 43.8639C18.5943 47.7939 22.9753 50.0473 28.1919 50.6239V55.384H18.9613C18.3363 55.384 17.7955 55.6127 17.3388 56.0695C16.882 56.5259 16.6535 57.067 16.6535 57.692C16.6535 58.316 16.882 58.858 17.3388 59.3145C17.7955 59.7709 18.3363 60 18.9613 60H42.0375C42.6624 60 43.2039 59.771 43.66 59.3145C44.1173 58.8582 44.3459 58.3161 44.3459 57.692C44.3459 57.0671 44.1173 56.526 43.66 56.0695C43.204 55.6127 42.6625 55.384 42.0375 55.384H32.808V50.6239C38.0237 50.0473 42.4044 47.7939 45.9502 43.8639C49.496 39.934 51.2696 35.3124 51.2696 29.9999V25.3843C51.2696 24.7596 51.0407 24.2192 50.5832 23.762Z" fill={f || '#076D52'} />
    </svg>
);

export const RecordingStopIcon: FC<IconProps> = ({ h, w, f }) => (
    <svg width={w || 40} height={h || 40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M20 0C8.96195 0 0 8.96195 0 20C0 31.038 8.96195 40 20 40C31.038 40 40 31.038 40 20C40 8.96195 31.038 0 20 0ZM20 2.92683C29.4234 2.92683 37.0732 10.5766 37.0732 20C37.0732 29.4234 29.4234 37.0732 20 37.0732C10.5766 37.0732 2.92683 29.4234 2.92683 20C2.92683 10.5766 10.5766 2.92683 20 2.92683ZM27.3171 23.9034V16.0985C27.3171 14.2127 25.7883 12.6839 23.9024 12.6839H16.0976C14.2117 12.6839 12.6829 14.2127 12.6829 16.0985V23.9034C12.6829 25.7892 14.2117 27.318 16.0976 27.318H23.9024C25.7883 27.318 27.3171 25.7892 27.3171 23.9034Z" fill={f || '#076D52'} />
    </svg>

);

export const PlayIcon: FC<IconProps> = ({ h, w, f }) => (
    <svg width={w || 40} height={h || 40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.5974 19.218L18.2916 13.1802C17.9867 12.9593 17.5821 12.926 17.248 13.0977C16.9112 13.2681 16.7009 13.6142 16.7009 13.9882V26.0597C16.7009 26.4377 16.9112 26.7824 17.248 26.9528C17.3904 27.0247 17.5462 27.0606 17.7032 27.0606C17.9082 27.0606 18.1159 26.9954 18.2916 26.8663L26.5974 20.8339C26.8597 20.6409 27.0127 20.3427 27.0127 20.0259C27.0141 19.7038 26.857 19.407 26.5974 19.218Z" fill={f || '#076D52'} />
        <path d="M20.0007 0.00268555C8.95278 0.00268555 0 8.95547 0 20.0034C0 31.0472 8.95278 39.9974 20.0007 39.9974C31.0459 39.9974 40 31.0459 40 20.0034C40.0013 8.95547 31.0459 0.00268555 20.0007 0.00268555ZM20.0007 36.6604C10.8003 36.6604 3.34099 29.205 3.34099 20.0034C3.34099 10.8057 10.8003 3.34101 20.0007 3.34101C29.1997 3.34101 36.6577 10.8043 36.6577 20.0034C36.659 29.205 29.1997 36.6604 20.0007 36.6604Z" fill={f || '#076D52'} />
    </svg>
);

export const PauseIcon: FC<IconProps> = ({ h, w, f }) => (
    <svg width={w || 40} height={h || 40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2_2235)">
            <path d="M34.139 5.86104C26.3249 -1.95309 13.6763 -1.95427 5.8611 5.86104C-1.95303 13.6752 -1.9542 26.3237 5.8611 34.1389C13.6752 41.9531 26.3238 41.9542 34.139 34.1389C41.9531 26.3249 41.9542 13.6763 34.139 5.86104ZM20 36.7107C10.7857 36.7107 3.28926 29.2143 3.28926 20C3.28926 10.7856 10.7857 3.28928 20 3.28928C29.2143 3.28928 36.7107 10.7857 36.7107 20C36.7107 29.2143 29.2143 36.7107 20 36.7107Z" fill={f || '#076D52'} />
            <path d="M23.3369 12.5179C22.4298 12.5179 21.6945 13.2533 21.6945 14.1603V25.8396C21.6945 26.7466 22.4298 27.482 23.3369 27.482C24.2439 27.482 24.9792 26.7466 24.9792 25.8396V14.1603C24.9792 13.2533 24.244 12.5179 23.3369 12.5179Z" fill="#076D52" />
            <path d="M16.663 12.5179C15.756 12.5179 15.0206 13.2533 15.0206 14.1603V25.8396C15.0206 26.7466 15.756 27.482 16.663 27.482C17.5701 27.482 18.3054 26.7466 18.3054 25.8396V14.1603C18.3054 13.2533 17.5702 12.5179 16.663 12.5179Z" fill="#076D52" />
        </g>
        <defs>
            <clipPath id="clip0_2_2235">
                <rect width="40" height="40" fill="white" />
            </clipPath>
        </defs>
    </svg>

);
/* eslint-enable react/require-default-props */
