import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TimerState } from './timerTypes';

const initialState: TimerState = {
    currentTime: 0,
};

/* eslint-disable no-param-reassign */
export const timeSlice = createSlice({
    name: 'time',
    initialState,
    reducers: {
        setCurrentTime: (state: TimerState, action: PayloadAction<number>) => {
            state.currentTime = action.payload;
        },
        clearCurrentTime: (state: TimerState) => {
            state.currentTime = 0;
        },
    },
});

/* Export actions to set and clear the time */
export const { setCurrentTime, clearCurrentTime } = timeSlice.actions;

/* Export the reducer to add to the store */
export default timeSlice.reducer;
