import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { CombinedStates } from '../types';
import { fetchApiAuth } from '../utils/api';

import { addDeleteEventId } from './eventSlice';
import { DeleteEventResponse, EventCreateRequest, Event, GetEventsRequest, GetEventsResponse } from './eventTypes';

export const createEvent = createAsyncThunk<Event, EventCreateRequest, { state: CombinedStates, rejectValue: string }>(
    'event/create',
    async (payload, { getState, rejectWithValue }) => {
        try {
            const { audio_file, ...rest } = payload;

            // Directly access timer.currentTime from Redux state
            const { currentTime } = getState().timer;

            const formData = new FormData();
            Object.entries(rest).forEach(([key, value]) => {
                formData.append(key, String(value));
            });
            formData.append('occurred_at', String(currentTime));

            // Dynamically determine the file extension based on its MIME type
            const fileExtension = audio_file.type.split('/')[1];
            formData.append('audio_file', audio_file, `audio_file.${fileExtension}`);

            const response = await fetchApiAuth<Event>({
                method: 'POST',
                url: 'event',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: formData,
            });

            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errorMessage = (error.response?.data.message as string) || 'An unknown error occurred';
                notification.error({
                    placement: 'top',
                    message: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const getEvents = createAsyncThunk<GetEventsResponse, GetEventsRequest, { rejectValue: string }>(
    'getEvents',
    async (payload:GetEventsRequest, { rejectWithValue }) => {

        try {
            const { match_id, status } = payload;
            let reqUrl = `match/${match_id}/events`;
            if (status) reqUrl = `${reqUrl}?status=${status}`;

            const response = await fetchApiAuth<GetEventsResponse>({
                method: 'GET',
                url: reqUrl,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errorMessage = (error.response?.data.message as string) || 'An unknown error occurred';
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const deleteEvent = createAsyncThunk<DeleteEventResponse, string, { rejectValue: string }>(
    'deleteEvent',
    async (event_id, { dispatch, rejectWithValue }) => {

        dispatch(addDeleteEventId(event_id));

        try {

            const response = await fetchApiAuth<DeleteEventResponse>({
                method: 'DELETE',
                url: `event/${event_id}/delete`,
            });

            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errorMessage = (error.response?.data.message as string) || 'An unknown error occurred';
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const clearEvents = createAsyncThunk<null, void, {rejectValue:string}>(
    'event/clear',
    async () => {
        return null;
    },
);
