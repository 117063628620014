import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import lockr from 'lockr';
import { isUndefined, isObject } from 'underscore';

import appConstants from '../constants';
// load mock data if enabled
import './mock';

const {
    API_URL,
    AUTH_KEY,
    GOOGLE_MAPS_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = appConstants;

// ----- Helpers ----- //
// Get Api Url
const getApiUrl = () => `${API_URL}/api`;

// Get hostname url
const getBaseUrl = () => API_URL;

// Get locational data
const locationData = (name:string) => axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${name}&key=${GOOGLE_MAPS_KEY}`);

interface payloadProps {
    [key: string]: []|object|null|undefined|string|number;
}
// Format params for api call
const formatParams = (payload: payloadProps, key:number|string) => {
    let params = payload;
    if (!isObject(payload)) {
        params = {};
        params[key || 'id'] = payload;
    }
    return params;
};

// ----- Auth Key Helpers -----//

/**
 * Get Bearer token from storage
 * @return string
 */
const getAuthKey = () => lockr.get(AUTH_KEY);

/**
 * Get header object for auth token
 * @return object
 */
const getAuthHeaders = () => ({ Authorization: `Bearer ${getAuthKey()}` });

function getHeaders(customHeaders: Record<string, string>): Record<string, string> {
    return {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...customHeaders,
    };
}

// ----- Api Functions ----- //
interface OptsProps {
    method: string;
    url: string;
    body?: object;
    params?: object;
    headers?: object;
    data?: string|object|null|undefined;
}

function fetchApi<T>(opts: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const baseUrl = opts.baseURL ? opts.baseURL : getApiUrl();
    const ax = axios.create({
        baseURL: baseUrl,
    });

    const options = opts;
    if (opts.method?.toUpperCase() !== 'GET') {
        options.data = opts.data;
    }
    options.headers = getHeaders(opts.headers as Record<string, string>);

    return ax(options);
}

function fetchApiAuth<T>(opts:OptsProps):Promise<AxiosResponse<T>> {
    if (isUndefined(getAuthKey())) {
        return Promise.reject(new Error('not-authorised'));
    }

    const options = opts;
    const authDate = new Date();
    lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
    options.headers = { ...getHeaders(opts.headers as Record<string, string>), ...getAuthHeaders() };

    return fetchApi(options);
}

export {
    getApiUrl,
    getBaseUrl,
    locationData,
    formatParams,
    // -- Auth Key Helpers --//
    getAuthKey,
    getAuthHeaders,

    fetchApi,
    fetchApiAuth,
};
