import { optionProps } from './types';

export const MatchTypeOptions:optionProps[] = [
    { value: 'friendly', label: 'Friendly' },
    { value: 'league', label: 'League' },
    { value: 'cup', label: 'Cup' },
    { value: 'academy', label: 'Academy' },
    { value: 'training', label: 'Training' },
    { value: 'other', label: 'Other' },
];

export const MatchFormatOptions:optionProps[] = [
    { value: '5v5', label: '5v5' },
    { value: '7v7', label: '7v7' },
    { value: '9v9', label: '9v9' },
    { value: '11v11', label: '11v11' },
    { value: 'other', label: 'Other' },
];

export const AgeGroupOptions:optionProps[] = [
    { value: 'u6', label: 'u6 and below' },
    { value: 'u7', label: 'u7' },
    { value: 'u8', label: 'u8' },
    { value: 'u9', label: 'u9' },
    { value: 'u10', label: 'u10' },
    { value: 'u11', label: 'u11' },
    { value: 'u12', label: 'u12' },
    { value: 'u13', label: 'u13' },
    { value: 'u14', label: 'u14' },
    { value: 'u15', label: 'u15' },
    { value: 'u16', label: 'u16' },
    { value: 'u17', label: 'u17' },
    { value: 'u18', label: 'u18' },
    { value: 'senior', label: 'Senior' },
];

export const MatchLengthOptions:optionProps[] = [
    { value: '40', label: '40 mins' },
    { value: '50', label: '50 mins' },
    { value: '60', label: '60 mins' },
    { value: '70', label: '70 mins' },
    { value: '80', label: '80 mins' },
    { value: '90', label: '90 mins' },
];

export const GenderOptions:optionProps[] = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'mixed', label: 'Mixed' },
];
