import React from 'react';

import { Button } from 'antd';
import { useNavigate } from 'react-router';

import FootballIcon from '../../assets/images/footballIcon.svg';
import Main from '../../components/layout/Main';

import './confirmationScreen.scss';

const ConfirmationScreen = () => {
    const navigation = useNavigate();

    const footerContent = (
        <Button onClick={() => navigation('/dashboard')} type="primary" shape="round" size="large" block className="primary-btn-style">
            Back to Home
        </Button>
    );

    return (
        <Main
            showHeader
            className="confirmation"
            title={<div>Confirmation</div>}
            headerTitle={<div />}
            floatingHeader={false}
            backLink={<div />}
            showFooter
            floatingFooter
            footerContent={footerContent}>
            <div className="content-wrap">
                <div className="image-wrap">
                    <img src={FootballIcon} alt="" className="icon-img" />
                </div>
            </div>

            <div className="main-content mt-2 mb-8 confirmation-content-wrap">
                <h2 className="h-1 title-style primary-color">Match Data Submitted!</h2>
                <p>Thank you for using KickStat to record your match data. You will receive a report based on your data via email shortly.</p>
            </div>
            <div />
        </Main>
    );
};

export default ConfirmationScreen;

ConfirmationScreen.propTypes = {};
