import React from 'react';

import { Button, Modal } from 'antd';

const ExitMatchModal: React.FC<{ open: boolean; confirmLoading: boolean; onCancel: () => void; onExit: () => void }> = ({
    open,
    confirmLoading,
    onCancel,
    onExit,
}) => (
    <Modal
        title="You’re Exiting The Match"
        className="confirmation-modal-wrap"
        open={open}
        confirmLoading={confirmLoading}
        maskClosable={false}
        onCancel={onCancel}
        footer={[
            <Button key="back" onClick={onExit}>
                Yes, Exit Match
            </Button>,
            <Button key="submit" type="primary" onClick={onCancel}>
                No, Continue Recording
            </Button>,
        ]}>
        <p>Are you sure you want to exit this match? All game data will be lost.</p>
    </Modal>
);

export default ExitMatchModal;
