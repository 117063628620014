type AppConfig = {
    ENV: string;
    VERSION: string;
    DEBUG: boolean;
    MOCK: boolean;
    BACKEND: 'yii' | 'laravel';
    APP_NAME: string;
    APP_URL: string;
    API_URL: string;
    BUGSNAG_KEY: string;
    GOOGLE_MAPS_KEY: string;
    AUTH_KEY: string;
    LOCALSTORAGE_EXPIRES_KEY: string;
    LOCALSTORAGE_EXPIRES_TIME: number;
};

// .env constants
const {
    REACT_APP_ENV,
    REACT_APP_VERSION,

    REACT_APP_APP_NAME,
    REACT_APP_URL,
    REACT_APP_API_URL,

    REACT_APP_BUGSNAG_KEY,
    REACT_APP_GOOGLE_MAPS_KEY,
} = process.env;

// APP CONSTANTS
const app:AppConfig = {
    ENV: REACT_APP_ENV || 'local',
    VERSION: REACT_APP_VERSION || '0.0.0',
    DEBUG: REACT_APP_ENV === 'local',
    MOCK: false,
    BACKEND: 'yii', // or 'laravel'

    APP_NAME: REACT_APP_APP_NAME || 'KickStats',

    APP_URL: REACT_APP_URL || 'http://localhost:3000',
    API_URL: REACT_APP_API_URL || 'http://localhost:3000/api',

    BUGSNAG_KEY: REACT_APP_BUGSNAG_KEY || '',
    GOOGLE_MAPS_KEY: REACT_APP_GOOGLE_MAPS_KEY || '',

    AUTH_KEY: 'auth-key',
    LOCALSTORAGE_EXPIRES_KEY: 'session-token-expiry',
    LOCALSTORAGE_EXPIRES_TIME: 30, // minutes
};

export default app;
