import { combineReducers } from 'redux';

import eventReducer from './event/eventSlice';
import global from './global/globalSlice';
import matchReducer from './match/matchSlice';
import timerReducer from './timer/timerSlice';
import user from './user/userSlice';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    // ... your other reducers here ...
    global,
    user,
    match: matchReducer,
    event: eventReducer,
    timer: timerReducer,
});
export default appReducers;
