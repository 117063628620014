import React from 'react';

import ForgetPasswordForm from '../../components/Form/ForgetPasswordForm';
import AuthLayout from '../../components/layout/AuthLayout';

function ForgetPasswordPage() {

    return (
        <AuthLayout>
            <ForgetPasswordForm />
        </AuthLayout>
    );
}
export default ForgetPasswordPage;
