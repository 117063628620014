import React from 'react';

import { Row, Col, Layout } from 'antd';

import logoSrc from '../../../assets/images/logo-circular.svg';
import Menu from '../Menu';

import type { HeaderProps } from './types';

import './header.css';

const Header = (props: HeaderProps) => {
    const {
        menu,
        backLink,
        title,
        menuPlacement,
        menuWidth,
        floating,
        beforeHeader,
        afterHeader,
    } = props;

    return (
        <div className={`header ${floating ? 'floating-header' : ''}`}>
            <div className="main-content">
                {beforeHeader}
                <Layout.Header className="header__inner">
                    <Row justify="space-between" align="middle" style={{ height: 'inherit' }}>
                        <Col>
                            <Row align="middle">
                                <Col>
                                    {menu ? <Menu items={menu} placement={menuPlacement} width={menuWidth} /> : null}
                                </Col>
                                <Col>
                                    {backLink}
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <h2 className="h-1 title-style primary-color">{title}</h2>
                        </Col>
                        <Col>
                            <div className="image-wrap">
                                <img src={logoSrc} alt="logo" className="main-logo" width={30} />
                            </div>
                        </Col>
                    </Row>
                </Layout.Header>
                {afterHeader}
            </div>
        </div>
    );
};

Header.defaultProps = {
    logo: '',
    beforeHeader: null,
    afterHeader: null,
};

export default Header;
