import React, { FC, useEffect, useState } from 'react';

import { Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppDispatch, CombinedStates } from '../../../core/types';
import { loginUser } from '../../../core/user/userApi';

import './loginForm.scss';
import { FinishedFailedProps, valuesProps } from './types';

const LoginForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigation = useNavigate();
    const [isFormValid, setIsFormValid] = useState(false);
    const { isFetching, isLoggedIn } = useSelector((state: CombinedStates) => state.user);

    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setIsFormValid(true))
            .catch(() => setIsFormValid(false));
    }, [form, Form.useWatch([], form)]);

    useEffect(() => {
        if (isFormValid) {
            setIsLoading(isFetching);
        }
    }, [isFetching]);

    const onFinish = (values: valuesProps) => {
        dispatch(loginUser({
            email: values.email,
            password: values.password,
        }));
    };

    const onFinishFailed: FC<FinishedFailedProps> = ({ errorFields }) => {
        form.scrollToField(errorFields[0]?.name);
        return null;
    };

    const handleOnClickForget = () => {
        navigation('/forget-password');
    };

    useEffect(() => {
        if (isLoggedIn) {
            navigation('/dashboard');
        }
    }, [isLoggedIn]);

    return (
        <div className="login-form-box">
            <div className="form">
                <h3 className="form-title title-style h-1 primary-color">Login</h3>
                <p className="mb-4">Enter the details for your KickStats account.</p>
                <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical">
                    <Form.Item
                        label="Email"
                        name="email"
                        validateTrigger="onBlur"
                        rules={[
                            { type: 'email', message: 'The input is not a valid email!' },
                            { required: true, message: 'Please enter your email.' },
                        ]}>
                        <Input placeholder="Enter your email" autoComplete="username" />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please enter your password.' }]}>
                        <Input.Password placeholder="Enter your password" autoComplete="current-password" />
                    </Form.Item>
                    <div className="forgot-password-wrap">
                        <Button onClick={handleOnClickForget} type="link" className="button-link">
                            Forgot password?
                        </Button>
                    </div>
                    <Form.Item>
                        <Button type="primary" shape="round" size="large" htmlType="submit" block loading={isLoading} disabled={!isFormValid}>
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default LoginForm;
