import React, { FC, useEffect, useState } from 'react';

import { Form, Input, Button, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { AppDispatch, CombinedStates } from '../../../core/types';
import { resetPassword } from '../../../core/user/userApi';

import './resetPasswordForm.scss';
import { FinishedFailedProps, valuesProps, ResetPasswordFormProps } from './types';

const ResetPasswordForm: FC<ResetPasswordFormProps> = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { isFetching, error } = useSelector((state: CombinedStates) => state.user);
    const { token, email } = useParams<{ token: string, email: string }>();
    const navigate = useNavigate();

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [form] = Form.useForm();

    const formValues = Form.useWatch([], form); // Watch form values

    useEffect(() => {
        form
            .validateFields({ validateOnly: true })
            .then(() => setIsFormValid(true))
            .catch(() => setIsFormValid(false));
    }, [form, formValues]);

    const onFinish = (values: valuesProps) => {
        dispatch(resetPassword({
            email,
            password: values.newPassword,
            passwordConfirmation: values.confirmPassword,
            token,
        }));
        setIsFormSubmitted(true);
    };

    const onFinishFailed = ({ errorFields }: FinishedFailedProps) => {
        form.scrollToField(errorFields[0]?.name);
    };

    useEffect(() => {
        if (isFormSubmitted && error == null && !isFetching) {
            navigate('/');
        }
    }, [isFetching, error, isFormSubmitted]);

    return (
        <div className="login-form-box">
            <div className="form">
                <h3 className="form-title title-style h-1 primary-color">Reset Password</h3>
                <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form} layout="vertical">
                    <Form.Item label="New Password" name="newPassword" rules={[{ required: true, message: 'Password is required' }]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        dependencies={['newPassword']}
                        rules={[
                            { required: true, message: 'Confirm Password is required' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}>
                        <Input.Password />
                    </Form.Item>
                    <Row>
                        <Col xs={24}>
                            <Button
                                type="primary"
                                shape="round"
                                size="large"
                                htmlType="submit"
                                block
                                className="mt-2"
                                loading={isFetching}
                                disabled={!isFormValid}>
                                Reset password
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default ResetPasswordForm;
