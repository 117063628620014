/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { GlobalState } from './types';

const initialState = {
    version: null,
    breadcrumb: [],
    rehydrationCompleted: false,
    isFetching: false,
} as GlobalState;

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setVersion: (state, action) => {
            const { payload } = action;
            state.version = payload;
        },
        setBreadcrumb: (state, action) => {
            const { payload } = action;
            state.breadcrumb = payload;
        },
        rehydrationCompleted: (state, action) => {
            const { payload } = action;
            state.rehydrationCompleted = payload;
        },
    },
});

// this is for dispatch
export const {
    setVersion,
    setBreadcrumb,
    rehydrationCompleted,
} = globalSlice.actions;

// this is for configureStore
export default globalSlice.reducer;
