import React from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';

import Routes from './core/routes';
import { store, persistor } from './core/store';
import DebugWrapper from './core/utils/debug';
import './styles/style.scss';

const rootElement = document.getElementById('root') as HTMLElement;

const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <DebugWrapper>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter
                        // history={history}
                        basename="/">
                        <Routes />
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </DebugWrapper>
    </React.StrictMode>,
);
