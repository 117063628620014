import React from 'react';

import { Button, Modal, Spin } from 'antd';

import { LoadingIcon } from '../../Icons';

const ScoreReviewModal: React.FC<{
    open: boolean;
    confirmLoading: boolean;
    loadingScore: boolean;
    onCancel: () => void;
    onSubmit: () => void;
    onUpdate: () => void;
    ownTeamScore: number;
    opponentScore: number;
}> = ({
    open,
    confirmLoading,
    loadingScore,
    onCancel,
    onSubmit,
    onUpdate,
    ownTeamScore,
    opponentScore,
}) => (
    <Modal
        title="Review Match Score"
        className="confirmation-modal-wrap"
        maskClosable={false}
        open={open}
        confirmLoading={confirmLoading}
        onCancel={onCancel}
        footer={[
            <Button key="back" onClick={onUpdate} disabled={confirmLoading}>
                {loadingScore ? 'Please wait...' : 'No, Update Score'}
            </Button>,
            <Button key="submit" type="primary" onClick={onSubmit} loading={confirmLoading}>
                {loadingScore ? 'Please wait...' : 'Yes, Submit Data'}
            </Button>,
        ]}>
        {loadingScore
            ? (
                <div className="loading-score-wrap">
                    <Spin indicator={<LoadingIcon />} />
                    <p>Calculating Score</p>
                </div>
            )
            : (
                <>
                    <p>The score we believe based on your recordings is below. Please review and ensure this is the correct score before submitting your match data.</p>
                    <span className="team-score-wrap">
                        <strong>Own Team</strong>
                        <span className="score-wrap">{ownTeamScore} - {opponentScore}</span>
                        <strong>Opponent</strong>
                    </span>
                </>
            )}
    </Modal>
);

export default ScoreReviewModal;
